

export default
    {
        
       work:[

                    {
                        title: "Config Mgmt",
                        key: "cm",
                        desc: "Configuration Management Examples. I have extensive experience in Configuration Management and have used a plethora of tools.  Ansible is my current tool of choice.",
                        href: "../codesample.html",
                        gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                        github: "https://github.com/jcook05/ansible",
                        image: {
                            desc: "Configuration Management examples", 
                            src: "images/IMG_0111.JPG"      
                    },
                    diagram: {
                        desc: "",
                        image: ""     
                },
                codedata: "cmsample"
                },
                {
                    title: "CICD",
                    key: "cicd",
                    desc: "CICD Examples.  I have over a decade of experience in CICD and have expertise in a plethora of tools to include GitLabs, Jenkins, AWS CodeBuild and CodePipeline.",
                    href: "../codesample.html",
                    gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                    github: "https://github.com/jcook05/serverlessportfolio/blob/master/buildspec.yml",
                    image: {
                        desc: "Continuous Integration Continuous Deployment examples.  See the buildspec.yml and deploy.py for some of the CICD wiring for this site.", 
                        src: "images/IMG_0117.JPG"
                },
                diagram: {
                    desc: "DIAGRAM:  A CICD flow illustrating an AWS, Jenkins, Docker and Kubernetes environment.",
                    image: {
                        src: "images/jendk.jpg",
                        desc: "CICD example code, Jenkins build and deploy"  
                    }
                },
                codedata: "cicdsample"
                },
                
                {
                    title: "Front End",
                    key: "frontend",
                    desc: "Front End Examples. I primarily develop in React and specialize in AWS static sites and Amplify Apps.",
                    href: "../codesample.html",
                    gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                    github: "https://github.com/jcook05/serverlessportfolio/blob/master/buildspec.yml",
                    image: {
                        desc: "Front end example code", 
                        src: "images/IMG_0117.JPG" 
                    },
                    diagram: {
                    desc: "Serverless React.js site CICD Flow",
                    image: {
                        src: "images/newport.jpg",
                        desc: "Portfolio example CICD"  
                    }
                },
                codedata: "reactsample"
                },
                {
                    title: "AWS Python",
                    key: "aws",
                    desc: "Python function libraries leveraging boto2 and boto3 for AWS automation.  Libraries would be maintained and reused throughout the DevOps team. Reusing libraries ensures consistency within a team, increases efficiency and decreases cost."  ,
                    href: "../codesample.html",
                    gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                    github: "https://github.com/jcook05/aws",
                    image: {
                        desc: "Python AWS Libraries", 
                        src: "images/IMG_0117.JPG"    
                }, 
                
                diagram: {
                        desc: "",
                        image: {
                                src: "",
                                desc: ""
                        }     
                },
                codedata: "pythonsample"

                },
                
                {
                    title: "Serverless",
                    key: "serverless",
                    desc: "Serverless Framework Examples. Examples of Lambda Functions implemented in various languages using the Serverless Framework",
                    href: "../codesample.html",
                    gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                    github: "https://github.com/jcook05/Serverless",
                    image: {
                        desc: "Serverless Framework", 
                        src: "images/IMG_0117.JPG"
                }, 
                
                diagram: {
                        desc: "",
                        image: {
                                src: "",
                                desc: ""
                        }     
                },
                codedata: "slssample"
                },
                
                {
                    title: "Terraform",
                    key: "Terraform",
                    desc: "Terraform Examples. I use Terraform whenever possible for IAC projects.",
                    href: "https://github.com/jcook05/terraform",
                    gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                    github: "https://github.com/jcook05/terraform",
                    image: {
                        desc: "Terraform", 
                        src: "images/IMG_0117.JPG"  
                }, 
                diagram: {
                        desc: "",
                        image: {
                                src: "",
                                desc: ""
                        }     
                },
                codedata: "terraformsample"
                },
                {
                    title: "Cloud Formation",
                    key: "CloudFormation",
                    desc: "Cloud Formation Examples.  If a client prefers Cloud Formation for AWS specific IAC, I'm happy to oblige.",
                    href: "https://github.com/jcook05/cloudformation",
                    gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                    github: "https://github.com/jcook05/cloudformation",
                    image: {
                        desc: "CloudFormation", 
                        src: "images/IMG_0117.JPG"  
                }, 
                
                diagram: {
                        desc: "",
                        image: {
                                src: "",
                                desc: ""
                        }     
                },
                codedata: "cfsample"
                },
                {
                    title: "Event Driven Security",
                    key: "eds",
                    desc: "Event Driven Security Examples.  EDS is great for ensuring newly created S3 Buckets are secure or Security Groups have the appropriate rules.",
                    href: "../codesample.html",
                    gdesc: "Github contains code for demonstration purposes not actual client deliverables.",
                    github: "https://github.com/jcook05/EdSec",
                    image: {
                        desc: "EdSec", 
                        src: "images/IMG_0117.JPG" 
                    }, 
                    
                    diagram: {
                        desc: "",
                        image: {
                                src: "",
                                desc: ""
                        }     
                    },
                    codedata: "edssample"
                },
                
       ],
        skills: [
            // Add Or Remove The Link Accordingly
            {   name:'AWS Solutions Architect',
                image:"holder.js/800x400?text=AWS Solutions Architect&bg=373940",
                desc:""
            },
            {
                name: 'DevOps Architect',
                image: "holder.js/800x400?text=DevOps Architect&bg=373940"
            },
            {
                name: 'AWS Serverless',
                image: "holder.js/800x400?text=AWS Serverless&bg=373940"
            },
            {
                name: 'Cloud Engineering',
                image: "holder.js/800x400?text=Cloud Engineering&bg=373940"
            },
            {
                name: 'Tech Team Leadership',
                image: "holder.js/800x400?text=Tech Team Leadership&bg=373940"
            },
            {
                name: 'K8s',
                image: "holder.js/800x400?text=Kubernetes&bg=373940"
            },
            {
                name: 'Docker',
                image: "holder.js/800x400?text=Docker&bg=373940"
            },
            {
                name: 'Python',
                image: "holder.js/800x400?text=Python&bg=373940"
            }

        ]
    }